// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-khoj-about-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/khoj/about.js" /* webpackChunkName: "component---src-pages-khoj-about-js" */),
  "component---src-pages-khoj-contribute-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/khoj/contribute.js" /* webpackChunkName: "component---src-pages-khoj-contribute-js" */),
  "component---src-pages-khoj-explore-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/khoj/explore.js" /* webpackChunkName: "component---src-pages-khoj-explore-js" */),
  "component---src-pages-khoj-index-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/khoj/index.js" /* webpackChunkName: "component---src-pages-khoj-index-js" */),
  "component---src-pages-khoj-search-old-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/khoj/search-old.js" /* webpackChunkName: "component---src-pages-khoj-search-old-js" */),
  "component---src-pages-khoj-search-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/khoj/search.js" /* webpackChunkName: "component---src-pages-khoj-search-js" */),
  "component---src-pages-khoj-status-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/khoj/status.js" /* webpackChunkName: "component---src-pages-khoj-status-js" */),
  "component---src-pages-learn-more-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/learn-more.js" /* webpackChunkName: "component---src-pages-learn-more-js" */),
  "component---src-pages-page-2-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-search-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-test-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-text-extract-js": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/text-extract.js" /* webpackChunkName: "component---src-pages-text-extract-js" */),
  "component---src-pages-learn-mdx": () => import("/home/runner/work/web-apps/web-apps/packages/service-ui/src/pages/learn.mdx" /* webpackChunkName: "component---src-pages-learn-mdx" */)
}

