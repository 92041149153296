module.exports = [{
      plugin: require('/home/runner/work/web-apps/web-apps/packages/service-ui/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/web-apps/web-apps/packages/service-ui/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/gatsby-plugin-typography"},
    },{
      plugin: require('/home/runner/work/web-apps/web-apps/packages/service-ui/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/web-apps/web-apps/packages/service-ui/src/components/centered-container.js"}},
    },{
      plugin: require('/home/runner/work/web-apps/web-apps/packages/service-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
